<template>
  <div>
      <v-main>
        <v-container fluid>
            <v-card>
              <v-card-title>
                <v-col cols="12" md="6" align="left" class="pa-0">
                  Notification
                </v-col>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="listNotif"
                :options.sync="pagination"
                :loading=loading
                :footer-props="footerProps"
                :server-items-length="notifAmount"
              >
                <template v-slot:top>
                    <v-toolbar flat>
                        <!-- <v-spacer /> -->
                        <v-dialog
                          v-model="dialog"
                          max-width="500px"
                        >
                          <v-card class="mb-5 pa-0">
                            <v-col align='left' class="pa-0 pl-2 pt-1 pb-1" :style="{backgroundColor: '#f5f7f5'}">
                              <img alt="Bufu logo" src="../../assets/bufu-logo-trans.png" height="20"> Koperasi BUFU • {{editedItem.notifTag}}
                            </v-col>
                             <v-col align='left' class="pa-0 pl-2 pt-1" :style="{backgroundColor: '#dedede'}">
                              {{editedItem.notifTitle}}
                            </v-col>
                            <v-col align='left' class="pa-0 pl-2 pb-1" :style="{backgroundColor: '#dedede'}">
                              <span :style="{color: '#6e6e6e'}">{{editedItem.notifDesc}}</span>
                            </v-col>
                          </v-card>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="addItem()"
                            >
                              Tambah Notifikasi
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{formTitle}}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.notifTitle"
                                      label="Judul Notif"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.notifDesc"
                                      label="Deskripsi Notif"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-select
                                      v-model="editedItem.notifTag"
                                      :items="['Simpanan Wajib', 'Verifikasi Foto', 'Verifikasi KTP']"
                                      label="Tag Notif"
                                  ></v-select>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    md="12"
                                  >
                                    <v-text-field
                                      v-model="editedItem.notifPush"
                                      type='date'
                                      label="Jadwal Notif"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="red darken-1"
                                text
                                @click="close"
                              >
                                Batal
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.Aksi`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    color="green"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{msgSnack}} 
            </v-snackbar>
        </v-container>
      </v-main>
    <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
  </div>
  
</template>

<script>
import OverlayProgress from '@/components/OverlayProgress.vue'

export default {
  components: {
    OverlayProgress
  },
  data() {
    return {
        pagination: {
          page: 1,
          itemsPerPage: 20,
        },
        footerProps: {
          itemsPerPageOptions: [20, 50, 100, -1],
          showFirstLastPage: true,
          showCurrentPage: true,
        },
        loading: true,
        notifAmount: 0,
        headers: [
          {
            text: 'Title',
            align: 'start',
            sortable: false,
            value: 'notifTitle',
          },
          { text: 'Description', sortable: false, value: 'notifDesc' },
          { text: 'Tag', sortable: false, value: 'notifTag' },
          { text: 'Schedule Notif', sortable: false, value: 'notifPush' },
          { text: 'Viewed', sortable: false, value: 'notifViewed' },
          { text: '', value: 'Aksi', sortable: false },
        ],
        editedItem: {
          notifId: null,
          notifTitle: null,
          notifDesc: null,
          notifTag: null,
          notifPush: null,
          notifViewed: null,
        },
        defaultItem: {
          notifId: null,
          notifTitle: null,
          notifDesc: null,
          notifTag: null,
          notifPush: null,
          notifViewed: null,
        },
        listNotif: [],
        snackbar: false,
        timeout: 4000,
        msgSnack: '',
        editIndex: -1,
        dialog: false
    }
  },
  computed:{
    formTitle(){
      return this.editedIndex === -1 ? 'Tambah Notif' : 'Edit Notif'
    }
  },
  watch:{
    pagination:{
      handler(){
        this.fetchNotif()
      },
      deep:true
    }
  },
  methods:{
    addItem(){
      this.editedIndex = -1
      this.editedItem = Object.assign({}, this.defaultItem)
    },
    editItem(item){
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this.listNotif.indexOf(item)
      this.dialog = true
    },
    deleteItem(item){
      this.editedItem = Object.assign({}, item)
      this.editedIndex = this.listNotif.indexOf(item)
      this.delete()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    save() {
      var url
      url = this.$root.api.post.notification

      this.$root.api.sentAPI(
      {
          method: 'post',
          api: url,
          form: this.editedItem
      }).then((res) => {
          if(res.status.status === 'Success'){
            this.fetchNotif()
            this.close()
          }else{
            this.snackbar = true
            this.msgSnack = res.status.message
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
    },
    delete() {
      var url = this.$root.api.del.notification
      var ids = []

      ids.push(this.editedItem.notifId)

      this.$root.api.sentAPI(
      {
          method: 'delete',
          api: url,
          form: ids
      }).then((res) => {
          if(res.status.status === 'Success'){
            this.fetchNotif()
          }
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false
    },
    async fetchNotif(){
      this.loading = true
      const {page, itemsPerPage} = this.pagination
      var url = this.$root.api.get.member
      if (itemsPerPage > -1) {
        url = this.$root.api.get.notification+'?start='+(page-1)+'&limit='+itemsPerPage
      }
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: url
      }).then((res) => {
          this.notifAmount = res.data.count
          this.listNotif = res.data.list
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
      if(!this.$root.isError) this.$root.overlay = false 
      this.loading = false
    },
  },
  mounted() {
    this.fetchNotif()
  }
}
</script>
<style scoped>
</style>
